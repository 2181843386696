<template>
    <v-tab v-bind="$attrs" :disabled="!hasRole(allowedRoles) || disabled" v-on="$listeners">
        <template v-for="(_, slot) of $slots" v-slot:[slot]><slot :name="slot" /></template>
    </v-tab>
</template>

<script>
import allowedRolesMixin from '@/components/security/allowedRolesMixin'

export default {
    name: 'GatedTab',
    mixins: [allowedRolesMixin],
}
</script>

<style></style>
