<template>
    <parent-layout title="Users and Roles">
        <v-tabs>
            <gated-tab
                link
                :to="{ name: 'users' }"
                :allowed-roles="[roles.ROLE_ILLUMINATE_EMPLOYEE]"
            >
                Users
            </gated-tab>
            <gated-tab
                link
                :to="{ name: 'roles' }"
                :allowed-roles="[roles.ROLE_ILLUMINATE_EMPLOYEE]"
            >
                Roles
            </gated-tab>
        </v-tabs>
        <router-view />
    </parent-layout>
</template>

<script>
import ParentLayout from '@/components/layout/ParentLayout'
import GatedTab from '@/components/security/GatedTab'
import roles from '@/helpers/security/roles.js'

export default {
    components: { ParentLayout, GatedTab },
    data() {
        return {
            pageTitle: 'Imt Overview',
            roles: roles,
        }
    },
    computed: {},
}
</script>
