import roles from '@/helpers/security/roles'
import { mapGetters } from 'vuex'

const allowedRolesMixin = {
    props: {
        allowedRoles: {
            type: Array,
            default: () => [roles.ROLE_SUPER_ADMIN],
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    computed: { ...mapGetters({ hasRole: 'auth/hasRole' }) },
}

export default allowedRolesMixin
